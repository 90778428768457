/* global Component */
class columnsSectionComponent extends Component {

    static name() {
        return "columnsSectionComponent";
    }

    static componentName() {
        return "columnsSectionComponent";
    }

    getProps() {
        return  ['items','config'];
    }

    data() {
        return {
            hasMobileBanner: false,
            isMobile: $(window).width() <= 991
        };
    }

    getComputed() {
        return {
            columnClass() {
                return `col-${12 / this.config.ColumnSize}`;
            },
        };
    }

    mounted() {
        return function () {
            this.$nextTick(()=>{
                let searchOnlyMobileImage = this.items.findIndex(function (item, index) {
                    return item.fields.OnlyMobile == true;
                });
                this.hasMobileBanner = searchOnlyMobileImage != -1 && this.isMobile;
            });
        };
    }

    getMethods() {
        return {
            isAppRouter:this.isAppRouter,
            itemCarouselMove:this.itemCarouselMove
        };
    }

    isAppRouter(item) {
        if(item.fields.ItemLink && item.fields.ItemLink.length > 0 && (item.fields.ItemLink[0] === '#' || item.fields.ItemLink[0] === '/'  || item.fields.ItemLink[0] === '.'))
            return true;
        return false;
    }

    itemCarouselMove(direction){
        $(this.$refs[this.UIComponentID]).carousel(direction);
    }

    getTemplate() {
        return `<div class="container" :class="{'my-2':config.HideTitle===1}">
                    <h5 class="col-sm-12 section-title" v-if="config.HideTitle!==1">{{config.Title}} <small>({{items.length}})</small></h5>
                    <div class="row">
                        <div class="oo-image-container" :class="columnClass" v-for="item of items">
                            <template v-if="item.fields.ItemLink">
                                <template v-if="item.fields.ItemLink.startsWith('http')">
                                    <a :href="item.fields.ItemLink" target="_blank" class="w-100">
                                        <img :src="item.fields.ItemPhotoUri" alt="" class="office-image">
                                        <div v-if="item.fields.ItemName" class="text-center py-3 py-md-4">
                                            <p class="text-uppercase highlighted">{{item.fields.ItemName ? (item.fields.ItemName).split('_')[0] : ''}}</p>
                                            <p class="secondary-text">{{item.fields.ItemName ? (item.fields.ItemName).split('_')[1] : ''}}</p>
                                        </div>
                                    </a>
                                </template>
                                <template v-else>
                                    <router-link :to="item.fields.ItemLink.replace('#','')" class="w-100">
                                        <img :src="item.fields.ItemPhotoUri" alt="" class="office-image">
                                        <div v-if="item.fields.ItemName" class="text-center py-3 py-md-4">
                                            <p class="text-uppercase highlighted">{{item.fields.ItemName ? (item.fields.ItemName).split('_')[0] : ''}}</p>
                                            <p class="secondary-text">{{item.fields.ItemName ? (item.fields.ItemName).split('_')[1] : ''}}</p>
                                        </div>
                                    </router-link>
                                </template>
                            </template>
                            <template v-else>
                                <img :src="item.fields.ItemPhotoUri" alt="" class="office-image">
                                <div v-if="item.fields.ItemName" class="text-center py-3 py-md-4">
                                    <p class="text-uppercase highlighted">{{item.fields.ItemName ? (item.fields.ItemName).split('_')[0] : ''}}</p>
                                    <p class="secondary-text">{{item.fields.ItemName ? (item.fields.ItemName).split('_')[1] : ''}}</p>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>`;
    }
}

columnsSectionComponent.registerComponent();